import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import './campaigns.scss'
import { CDN_IMG } from '../endpoints'

export const SUBSCRIPTION_STATUS_ALL = 'SUBSCRIPTION_STATUS_ALL'
export const SUBSCRIPTION_STATUS_WITH_SUBSCRIPTION = 'SUBSCRIPTION_STATUS_WITH_SUBSCRIPTION'
export const SUBSCRIPTION_STATUS_WITH_NO_SUBSCRIPTION = 'SUBSCRIPTION_STATUS_WITH_NO_SUBSCRIPTION'

export const FIRST_SET_CAMPAIGNS = {
  SCIENCE: {
    price: 99
  },
  OFFER99: {
    price: 99
  },
  HELLO99: {
    price: 99
  },
  LONGWEEKEND: {
    price: 111
  },
  FIREWORKS: {
    price: 103.99
  },
  VIP15: {
    price: 110.49,
    priceSaved: 80
  },
  VIP10: {
    price: 116.99,
    priceSaved: 73
  },
  OFF15PERCENT: {
    price: 110.49,
    priceSaved: 80
  },
  OFF10PERCENT: {
    price: 116.99,
    priceSaved: 73
  },
  LDW21: {
    price: 99,
    priceSaved: 90
  },
  BLACKFRIDAY: {
    price: 99,
    priceSaved: 90
  },
  CYBERMONDAY: {
    price: 99,
    priceSaved: 90
  },
  BESTCYBERDEAL: {
    price: 99,
    priceSaved: 100
  },
  CYBERMONDAYDEAL: {
    price: 99,
    priceSaved: 100
  },
  BESTDEALYET: {
    price: 99,
    priceSaved: 100
  }
}

export const EYE_CREAM_CAMPAIGNS = {
  EARLYACCESS: {
    price: 99,
    startDate: 'November 24, 2021 23:59:59',
    endDate: 'December 5, 2021 23:59:59'
  },
  BRIGHTEYES: {
    price: 99,
    startDate: 'December 9, 2021 23:59:59',
    endDate: 'December 16, 2021 23:59:59'
  }
}

export const LONGWEEKEND_CAMPAIGNS = ['LONGWEEKEND']

export const BFCM_EARLY_ACCESS_PRICE = 99
export const BFCM_EARLY_ACCESS_CODE = 'EARLYACCESS'
export const BFCM_EARLY_ACCESS_END_DATE = 'October 21, 2022 23:59:59'

// const banner1 = () => {
//   return(
//     <div styleName="special-offer galentines">
//       Limited Time: Try Custom 3-Item Set for just <b styleName="orange">$99</b>
//     </div>
//   );
// };

// const banner2 = () => {
//   return(
//     <div styleName="special-offer galentines">
//       Extended 1 day: <b style={{color: "#F50132"}}>FREE LUXURY SILK MASK</b> with all sets!
//     </div>
//   );
// };

function Banner({ to = '/quiz', code, promo, freeGifts, middleText = 'for', children }) {
  const Component = to ? Link : 'div'
  return (
    <Component to={`${to}?promo=${code}`} styleName="special-offer">
      Use code <b>{code}</b> {middleText} {promo ? <b>{promo}</b> : null}
      {freeGifts > 0 &&
        ` and ${freeGifts === 1 ? 'a' : freeGifts} FREE ${freeGifts > 1 ? 'gifts' : 'gift'}`}
      {children}!
    </Component>
  )
}

function CyberMondayBanner() {
  return (
    <Switch>
      <Route exact path="/account/shop/eye/day-eye-cream">
        <Banner code="EYECREAM59" promo="$59 Single" freeGifts={1} to={null} />
      </Route>
      <Route exact path="/account/shop/eye/night-eye-cream">
        <Banner code="EYECREAM59" promo="$59 Single" freeGifts={1} to={null} />
      </Route>
      <Route exact path="/account/shop/eye">
        <Banner code="EYEDUO" promo="$89 Eye Duo" freeGifts={1} to="/account/shop/eye" />
      </Route>
      <Route exact path="/account/congratulations/">
        <Banner code="CYBERMONDAYDEAL" promo="50% OFF" freeGifts={2} />
      </Route>
      <Route path="/account/">
        <Banner code="VIP30" promo="30% OFF" to={null}>
          {' '}
          one time purchases sitewide
        </Banner>
      </Route>
      <Route exact path="/personalized-subscription">
        <Banner code="CYBERMONDAYDEAL" promo="50% OFF" freeGifts={2} />
      </Route>
      <Route exact path="/personalized-eye-cream-duo">
        <Banner code="EYEDUO" promo="$89 Eye Duo" freeGifts={1} to="/account/shop/eye" />
      </Route>
      <Route exact path="/">
        <Banner code="CYBERMONDAYDEAL" promo="50% OFF" freeGifts={2} />
      </Route>
    </Switch>
  )
}

function BlackFridayBanner() {
  return (
    <Switch>
      <Route exact path="/account/shop/eye/day-eye-cream">
        <Banner code="EYECREAM59" promo="$59 Single" freeGifts={1} to={null} />
      </Route>
      <Route exact path="/account/shop/eye/night-eye-cream">
        <Banner code="EYECREAM59" promo="$59 Single" freeGifts={1} to={null} />
      </Route>
      <Route exact path="/account/shop/eye">
        <Banner code="EYEDUO" promo="$89 Eye Duo" freeGifts={1} to="/account/shop/eye" />
      </Route>
      <Route exact path="/account/congratulations/">
        <Banner code="BESTDEALYET" promo="50% OFF" freeGifts={2} />
      </Route>
      <Route path="/account/">
        <Banner code="EYEDUO" to="/account/shop/eye">
          {' '}
          first time Eye Duo Subscribers
        </Banner>
      </Route>
      <Route exact path="/personalized-subscription">
        <Banner code="BESTDEALYET" promo="50% OFF" freeGifts={2} />
      </Route>
      <Route exact path="/personalized-eye-cream-duo">
        <Banner code="EYEDUO" promo="$89 Eye Duo" freeGifts={1} to="/account/shop/eye" />
      </Route>
      <Route exact path="/">
        <Banner code="BESTDEALYET" promo="50% OFF" freeGifts={2} />
      </Route>
    </Switch>
  )
}

function HolidaySkinBanner() {
  return (
    <Switch>
      <Route exact path="/">
        <Banner code="HOLIDAYSKIN" promo="50% OFF" freeGifts={2} />
      </Route>
    </Switch>
  )
}

function NewYearsBanner() {
  return (
    <Switch>
      <Route path="/account/">
        <Banner code="FRESHSTART" promo="$99 Eye Duo" freeGifts={1} to="/account/shop/eye" />
      </Route>
      <Route exact path="/">
        <Banner code="NEWSKIN" promo="50% OFF" freeGifts={1} />
      </Route>
    </Switch>
  )
}

//gmt = pst +8 hrs
export const campaigns = [
  //START === TEST === TEST === TEST ===
  //END === TEST === TEST === TEST ===
  {
    startDateTime: '2021-11-21T08:00:00.000Z', //start (gmt) - Sun 11/21 at 12:00am PT
    endDateTime: '2021-11-22T07:59:59.000Z', //end (gmt) -   Mon 11/22 at 11:59am PT
    heroImageCongratDesktop: `${CDN_IMG}results/1120mega-3products-desktop.png`,
    heroImageCongratMobile: `${CDN_IMG}results/1120mega-3products-mobile.png`,
    productName: '3-Product System + Free Gift',
    productDelivery:
      '3-product system auto-refilled. Cancel anytime. Free gift with your first order while supplies last.'
  },
  {
    id: 'blackfriday',
    startDateTime: process.env.BF_START_DATE ?? '2022-11-15T05:00:00.000Z', //start (gmt) - 11/15 at 12:00am ET
    endDateTime: process.env.BF_END_DATE ?? '2022-11-27T05:00:00.000Z', //end (gmt) - 11/27 at 12:00am ET
    banner: BlackFridayBanner,
    cartImage: `${CDN_IMG}home/bfcm-cart.png`,
    heroImageDesktop: `${CDN_IMG}home/bfcm-homepage-hero-desktop-1115-2150.png`,
    heroImageMobile: `${CDN_IMG}home/bfcm-homepage-hero-mobile-1115-2150.png`,
    heroImageCongratDesktop: `${CDN_IMG}home/bfcm-congrats-hero-desktop.png`,
    heroImageCongratMobile: `${CDN_IMG}home/bfcm-congrats-hero-mobile.png`,
    backgroundImageDesktop: `${CDN_IMG}home/bfcm-home-hero-bg-desktop.jpg`,
    backgroundImageMobile: `${CDN_IMG}home/bfcm-home-hero-bg-mobile.jpg`,
    productName: '3-Product System + Gifts',
    productDelivery: '3-product system auto-refilled. Cancel anytime.',
    ctaText: 'TAKE THE SKIN QUIZ',
    ctaStyle: undefined,
    promoCode: 'BESTDEALYET',
    subheadline: 'black friday is here',
    freeShippingText: 'Save $100 + 2 Free Gifts + Free Shipping',
    noDiscountPrice: 231
  },
  {
    id: 'cybermonday',
    startDateTime: process.env.CM_START_DATE ?? '2022-11-27T05:00:00.000Z', //start (gmt) - 11/27 at 12:00am ET
    endDateTime: process.env.CM_END_DATE ?? '2022-12-03T08:00:00.000Z', // end (gmt) - 12/03 at 12:00am PT
    subscriptionStatus: SUBSCRIPTION_STATUS_WITH_NO_SUBSCRIPTION,
    banner: CyberMondayBanner,
    cartImage: `${CDN_IMG}home/bfcm-cart.png`,
    heroImageDesktop: `${CDN_IMG}home/bfcm-homepage-hero-desktop-1115-2150.png`,
    heroImageMobile: `${CDN_IMG}home/bfcm-homepage-hero-mobile-1115-2150.png`,
    heroImageCongratDesktop: `${CDN_IMG}home/bfcm-congrats-hero-desktop.png`,
    heroImageCongratMobile: `${CDN_IMG}home/bfcm-congrats-hero-mobile.png`,
    backgroundImageDesktop: `${CDN_IMG}home/bfcm-home-hero-bg-desktop.jpg`,
    backgroundImageMobile: `${CDN_IMG}home/bfcm-home-hero-bg-mobile.jpg`,
    productName: '3-Product System + Gifts',
    productDelivery: '3-product system auto-refilled. Cancel anytime.',
    ctaText: 'TAKE THE SKIN QUIZ',
    ctaStyle: undefined,
    promoCode: 'CYBERMONDAYDEAL',
    subheadline: 'cyber monday is here',
    freeShippingText: 'Save $100 + 2 Free Gifts + Free Shipping',
    noDiscountPrice: 231
  },
  {
    id: 'holidayskin',
    startDateTime: '2022-12-07T08:00:00.000Z', //start (gmt) - 12/07 at 12:00am PT
    endDateTime: '2022-12-14T08:00:00.000Z', // end (gmt) - 12/14 at 12:00am PT
    subscriptionStatus: SUBSCRIPTION_STATUS_ALL,
    banner: HolidaySkinBanner,
    productName: '3-Product System + Gifts',
    promoCode: 'HOLIDAYSKIN'
  },
  {
    id: 'newyearssale',
    startDateTime: process.env.NY_SALE_START_DATE ?? '2023-01-01T05:00:00.000Z', //start (gmt) - 01/01 at 12:00am ET
    endDateTime: process.env.NY_SALE_END_DATE ?? '2023-01-17T08:00:00.000Z', // end (gmt) - 01/17 at 12:00am PT
    subscriptionStatus: SUBSCRIPTION_STATUS_ALL,
    banner: NewYearsBanner,
    cartImage: `${CDN_IMG}home/nysale-cart.png`,
    heroImageDesktop: `${CDN_IMG}home/nysale-homepage-hero-desktop.jpg`,
    heroImageMobile: `${CDN_IMG}home/nysale-homepage-hero-mobile.jpg`,
    heroImageCongratDesktop: `${CDN_IMG}home/nysale-congrats-hero-desktop.png`,
    heroImageCongratMobile: `${CDN_IMG}home/nysale-congrats-hero-mobile.png`,
    backgroundImageDesktop: `${CDN_IMG}home/nysale-homepage-hero-bg.jpg`,
    backgroundImageMobile: `${CDN_IMG}home/nysale-homepage-hero-bg.jpg`,
    productName: '3-Product System + Gift',
    promoCode: 'NEWSKIN',
    systemPrice: 99,
    freeShippingText: 'Save $100 + Free Gift + Free Shipping'
  },
  // only show cybermonday banner
  {
    startDateTime: '2021-11-30T07:59:59.000Z', //start (gmt) - Mon 11/29 at 12:00am PT
    endDateTime: '2021-12-06T07:59:59.000Z', //end (gmt) -   Sun 12/05 at 11:59pm PT
    subscriptionStatus: SUBSCRIPTION_STATUS_WITH_NO_SUBSCRIPTION,
    banner: CyberMondayBanner
  }
]

//gmt = pst +8 hrs
export const bfcmCampaign = [
  {
    id: 'blackfriday',
    startDateTime: process.env.BF_2023_START_DATE ?? '2023-11-14T05:00:00.000Z', //start (gmt) - 11/14 at 12:00am ET
    endDateTime: process.env.BF_2023_END_DATE ?? '2023-11-27T05:00:00.000Z', //end (gmt) - 11/27 at 12:00am ET
    congratsHeroBackgroundDesktop: `${CDN_IMG}rud/shop/bfcm2023-system-hero-bg-desktop.jpg`,
    congratsHeroImageDesktop: `${CDN_IMG}rud/shop/bfcm2023-system-hero-desktop.png`,
    congratsHeroImageDesktopSephora: `${CDN_IMG}landing/bfcm-sephora-hero-system-desktop.png`,
    congratsHeroBackgroundMobile: `${CDN_IMG}rud/shop/bfcm2023-system-hero-bg-mobile.jpg`,
    congratsHeroImageMobile: `${CDN_IMG}rud/shop/bfcm2023-system-hero-mobile.png`,
    congratsHeroImageMobileSephora: `${CDN_IMG}landing/bfcm2023-system-sephora-hero-mobile.png`,
    shopPageBannerDesktop: `${CDN_IMG}rud/shop/shop-upsell-banner-bfcm2023-best-deal-desktop.svg`,
    shopPageBannerMobile: `${CDN_IMG}rud/shop/shop-upsell-banner-bfcm2023-best-deal-mobile.svg`
  },
  {
    id: 'cybermonday',
    startDateTime: process.env.CM_2023_START_DATE ?? '2023-11-27T05:00:00.000Z', //start (gmt) - 11/27 at 12:00am ET
    endDateTime: process.env.CM_2023_END_DATE ?? '2023-12-05T08:00:00.000Z', // end (gmt) - 12/05 at 03:00am ET
    congratsHeroBackgroundDesktop: `${CDN_IMG}rud/shop/bfcm2023-system-hero-bg-desktop.jpg`,
    congratsHeroImageDesktop: `${CDN_IMG}rud/shop/bfcm2023-system-hero-desktop.png`,
    congratsHeroImageDesktopSephora: `${CDN_IMG}landing/bfcm-sephora-hero-system-desktop.png`,
    congratsHeroBackgroundMobile: `${CDN_IMG}rud/shop/bfcm2023-system-hero-bg-mobile.jpg`,
    congratsHeroImageMobile: `${CDN_IMG}rud/shop/bfcm2023-system-hero-mobile.png`,
    congratsHeroImageMobileSephora: `${CDN_IMG}landing/bfcm2023-system-sephora-hero-mobile.png`,
    shopPageBannerDesktop: `${CDN_IMG}rud/shop/shop-upsell-banner-bfcm2023-best-deal-desktop.svg`,
    shopPageBannerMobile: `${CDN_IMG}rud/shop/shop-upsell-banner-bfcm2023-best-deal-mobile.svg`
  }
]

export const checkCampaignSubscriptionStatus = (campaign, subscriptionStatus) => {
  const campaignSubscriptionStatus = campaign?.subscriptionStatus

  if (campaignSubscriptionStatus === SUBSCRIPTION_STATUS_ALL) {
    return true
  } else if (campaignSubscriptionStatus === SUBSCRIPTION_STATUS_WITH_SUBSCRIPTION) {
    return ['active', 'future', 'in_trial'].includes(subscriptionStatus)
  } else if (campaignSubscriptionStatus === SUBSCRIPTION_STATUS_WITH_NO_SUBSCRIPTION) {
    return !['active', 'future', 'in_trial'].includes(subscriptionStatus)
  } else {
    return true
  }
}
